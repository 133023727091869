.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.user-pane {
  margin-right: 10px;
}

.login-button {
  margin-right: 10px;
}

.navbar-brand-igm {
  margin-left: 10px;
}

.report-type-dropdown {
  margin-left: 10px;
  margin-right: 10px;
}

.report-list-spinner-holder {
  margin: 5px 0px 0px 10px;
} 

.report-list-table {
  height: calc(100% - 56px);
  height: -o-calc(100% - 56px); /* opera */
  height: -webkit-calc(100% - 56px); /* google, safari */
  height: -moz-calc(100% - 56px); /* firefox */

  font-size: 75%;

  flex-flow: column;
  display: flex;

  min-width: 580px;
}

.report-list-table .react-bootstrap-table {
  overflow: auto;
  padding-left: 50px;
  padding-right: 50px
}

.report-list-table>div>table {
  height: 100%;
  border: none;
}

.report-list-table>div>table {
  height: 100%;
  border: none;
}

/* This matches the div without any attributes that contains
   the rows inside the left-side panel TableTree */
.report-list-table div[role="treegrid"] div:not([role]) {
  height: calc(100% - 98px);
  height: -o-calc(100% - 98px); /* opera */
  height: -webkit-calc(100% - 98px); /* google, safari */
  height: -moz-calc(100% - 98px); /* firefox */  
}

.table td {
  border: none !important;
  padding: 7px;

  /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}  

.table th {
  border: none !important;
}  

.table tr {
  border: none !important;
}  

.react-bootstrap-table .row-expansion-style {
  padding: 0px !important;
}

.report-download-button {
  height: 24px !important;
  width: 24px !important;
  border-radius: 12px !important;
  text-align: center !important;
  font-size: 12px !important;
  padding: 0px !important;
  margin: -2px 0px 0px -2px !important;
}

.report-download-button svg {
  margin: -1px 0px 0px 0px !important;
}

.cog-report-download-button {
  height: 24px !important;
  width: 24px !important;
  border-radius: 12px !important;
  text-align: center !important;
  font-size: 12px !important;
  padding: 0px !important;
  margin: -2px 0px 0px -2px !important;
  background-color: #0d1eb9 !important;
  border-color: #0d1eb9 !important;  
}

.cog-report-download-button svg {
  margin: -1px 0px 0px 0px !important;
}

.page-buttons {
  padding-left: 50px;
  padding-right: 50px
}

.form-button {
  margin-right: 5px;
}

.email-address {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px
}

/* .spinner-holder {
  text-align: center;
  margin: 10px;
  display: inline;
} */

/* .spinner {
  margin:auto;
} */

/* .spinner-holder > div {
  display: inline;
} */

